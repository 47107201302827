<template>
    <q-layout view="lHh lpr lFf">
      <q-page-container>
        <q-page>
          <div class="login-view layout-padding">
              <router-view/>
          </div>
          <div class="text-center exp-view">
            <br>
            Experiencing any issues with our Customer Portal?
            <br>
            Please let us know via <a class="emailLink cursor-pointer" @click="mailTo"> email </a> or phone on 0203 818 7470.
          </div>
        </q-page>
      </q-page-container>
    </q-layout>
</template>

<script>

export default {
  name: 'LoginLayout',
  methods: {
    mailTo () {
      let link = 'mailto:portal@moneyboat.co.uk'
      const windowRef = window.open(link, '_blank')
      windowRef.focus()
    }
  }
}
</script>

<style lang="scss">

.login-view {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  background-color: #ffffff;
  max-height: 90vh;
}
.emailLink {
  color: #1c9edb;
  font-weight: 700;
  text-decoration: underline;
}
@media (max-height: 700px) {
  .exp-view {
    display: none;
  }
}
</style>
